// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/img/03-Backgrounds/level-1-background-blue.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/img/16-GAMES/G06-drawing/lesson-09/background.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".drawing-page-container .page-content[data-v-a050db02] {\n  width: 100%;\n  height: 100%;\n  background-repeat: no-repeat;\n  background-size: 100% 100%;\n  -moz-background-size: 100% 100%;\n  background-color: #f5f9ff;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  border-radius: 48px;\n  position: relative;\n}\n.drawing-page-container .page-content.lesson-9[data-v-a050db02] {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.drawing-page-container .page-content.lesson-9 .img-area img[data-v-a050db02] {\n      width: auto;\n}\n.drawing-page-container .page-content .img-area[data-v-a050db02] {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    left: 0;\n    top: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.drawing-page-container .page-content .img-area img[data-v-a050db02] {\n      width: 75%;\n      height: 75%;\n      -o-object-fit: cover;\n         object-fit: cover;\n      border-radius: 48px;\n}\n.drawing-page-container .page-content .lesson-33 img[data-v-a050db02] {\n    width: 100%;\n    height: 100%;\n}\n.drawing-page-container .page-content .lesson-59 img[data-v-a050db02] {\n    width: 100%;\n    height: 100%;\n}\n", ""]);
// Exports
module.exports = exports;
