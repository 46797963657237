<template>
  <div class="drawing-page-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @click.native="handleWrite"
    ></PageButton>
    <div class="page-content" :class="'lesson-' + lessonNo">
      <div class="img-area" :class="'lesson-' + lessonNo">
        <img :src="bgImg" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";

export default {
  name: "DrawingPage",
  components: {
    PageButton,
  },
  props: {
    bgImg: {
      type: String,
      require: true,
    },
    lessonNo: {
      type: Number,
      require: false,
    },
  },
  data() {
    return {
      startWritingSocketInfo: {},
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "draw",
            startNumberRange: 1,
            endNumberRange: 100,
          },
        ],
      },
    };
  },
  watch: {
    startWritingSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20063,
          data: { value },
          text: "DrawingPage按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("clickDrawPage", () => {
      this.handleWrite("socket", true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickDrawPage");
    // this.$bus.$emit("toolBarMouseClick");
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    // 开始书写
    handleWrite(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.startWritingSocketInfo = {
          index: Math.random(),
        };
      }
      console.log("开始画画");
      this.$bus.$emit("toolBarDrawLine");
    },
  },
};
</script>
<style scoped lang="scss">
.drawing-page-container {
  .page-content {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-color: #f5f9ff;
    background-image: url("../../../../assets/img/03-Backgrounds/level-1-background-blue.svg");
    background-size: cover;
    border-radius: 48px;
    position: relative;

    &.lesson-9 {
      background-image: url("../../../../assets/img/16-GAMES/G06-drawing/lesson-09/background.svg");

      .img-area img {
        width: auto;
      }
    }
    .img-area {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 75%;
        height: 75%;
        object-fit: cover;
        border-radius: 48px;
      }
    }
    .lesson-33 {
      img {
        width: 100%;
        height: 100%;
      }
    }
    .lesson-59 {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
